import React, { useState, useCallback } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import ArchivePostSingle from 'components/ArchivePostSingle';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';

import useClickOutside from 'hooks/useClickOutside';

// import { Main, BlogListSupport } from 'styles/support';

import SupportHeader from '../components/SupportHeader';
import { faqDataCommon } from '../utils/contentProvider/faqDataCommon';
import { signUp } from '../utils/contentProvider/function';

import 'styles/support.scss';
import NewFaq from '../components/NewFaq/NewFaq';

const supportFaq = [
  {
    q: 'How do I contact you in case I find a bug or face an issue?',
    a: (
      <>
        Send us an email at by mailing us at
        <a
          href="mailto:support@ruttl.com"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;support@ruttl.com&nbsp;
        </a>
        and we’ll respond to you at the earliest possible. You can also click on
        ‘Contact Us’ within your in-app menu &amp; submit your message.
        Alternatively, you can chat with us by clicking on the ‘Need Help’
        button on your app, or on our website (bottom-right corner).
      </>
    ),
  },
  {
    q: 'Does ruttl have a bug bounty program? Are there any rewards for finding bugs?',
    a: 'As of now, we do not have any bug bounty program.',
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));

const SupportNew = ({
  data: {
    allContentfulBlogPost: { edges: allPosts = [] },
  },
  location,
}) => {
  const [showSearch, setShowSearch] = useState(false);

  const { reference: searchcontainer } = useClickOutside(() => {
    if (showSearch === true) setShowSearch(false);
  });

  const toggleDropdown = () => {
    setShowSearch((state) => !state);
  };

  const toggleContactFeatureUpdate = useCallback(
    (toggle) => () => {
      toggle('feature-request');
    },
    [],
  );

  const [sliderActive, setSliderActive] = useState(0);
  const sliderClickHandler = useCallback((e) => {
    const { type } = e.target.closest('button').dataset;
    const sliderWrapper = e.target
      .closest('.popular-blog-slider')
      .querySelector('.single-popular-article');
    const totalLength =
      sliderWrapper.children.length -
      Math.round(
        sliderWrapper.offsetWidth / sliderWrapper.children[0].offsetWidth,
      );

    setSliderActive((state) => {
      if (type === 'next') return state === totalLength ? 0 : state + 1;
      return state <= 0 ? totalLength : state - 1;
    });
  }, []);

  const anchorClickHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target.closest('a');
    const { hash } = new URL(target.href);

    window.scrollTo({
      top: document.querySelector(hash).offsetTop - 100,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const insertFaq = useCallback(
    (index, items) => {
      if (faqDataCommon) {
        const faqClone = faqDataCommon.slice();
        faqClone.splice(index, 0, ...items);
        return faqClone;
      }
      return items;
    },
    [faqDataCommon],
  );

  return (
    <Layout hideHeader location={location}>
      {({ toggleContactUs, toggleSignup }) => (
        <>
          <Meta
            title="Support documentation 🔨 | ruttl "
            description="Support page to get quick access links for getting started, how to use ruttl, tools and tips, billing, FAQs and more."
            url="https://ruttl.com/support/"
          />
          <main className="support-styled-main">
            <SupportHeader toggleContactUs={toggleContactUs} />
            <section className="support-hero">
              <div className="container">
                <h1>How can we help you today?</h1>
                <div className="support-search" ref={searchcontainer}>
                  <p>Quick access links</p>

                  <button
                    type="submit"
                    onClick={toggleDropdown}
                    className={showSearch ? 'rotate' : ''}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}>
                      <path d="m6 9 6 6 6-6" />
                    </svg>
                  </button>
                  <div
                    className={
                      showSearch
                        ? 'is-active support-dropdown'
                        : 'support-dropdown'
                    }>
                    <Link to="/support/get-started/">Get Started</Link>
                    <Link to="/support/using-ruttl/">Using ruttl</Link>
                    <Link to="/support/tools-and-tips/">Tools and Tips</Link>
                    <Link to="/support/billing-and-plans/">
                      Billing and Plans
                    </Link>
                    <Link to="/support/invite-people/">Invite People</Link>
                    <a href="#faq" onClick={anchorClickHandler}>
                      FAQs
                    </a>
                  </div>
                </div>
                <p>
                  Popular topics:&nbsp;
                  <Link to="/support/get-started/">Getting started</Link>
                  ,&nbsp;
                  <Link to="/support/billing-and-plans/">
                    Billing and plans
                  </Link>
                  ,&nbsp;
                  <Link to="#faq" onClick={anchorClickHandler}>
                    FAQs
                  </Link>
                </p>
              </div>
            </section>
            <section className="grey-bg-support">
              <div className="container">
                <div className="support-block">
                  <div className="flex flex-wrap">
                    {[
                      {
                        id: 0,
                        url: '/support/get-started/',
                        img: '/assets/img/get-started.svg',
                        title: 'Get Started',
                        description:
                          'Learn more about the basics of ruttl. Create your account and understand your actions in ruttl',
                      },
                      {
                        id: 1,
                        url: '/support/using-ruttl/',
                        img: '/assets/img/using-ruttl.svg',
                        title: 'Using ruttl',
                        description:
                          'Check everything right from creating a project, to moving elements and replacing images.',
                      },
                      {
                        id: 2,
                        url: '/support/tools-and-tips/',
                        img: '/assets/img/tools-and-tips.svg',
                        title: 'Tools and Tips',
                        description:
                          'You might want to know our best tools and tips while using ruttl, like versioning, notifications, etc',
                      },
                      {
                        id: 3,
                        url: '/support/billing-and-plans/',
                        img: '/assets/img/billing-and-plans.svg',
                        title: 'Billing and Plans',
                        description:
                          'We help you with upgrading your ruttl account, and choosing the best plan for you and your team.',
                      },
                      {
                        id: 4,
                        url: '/support/invite-people/',
                        img: '/assets/img/invite-people.svg',
                        title: 'Invite People',
                        description:
                          'See how you can invite your team members to collaborate on website projects, or invite your client without their login needed.',
                      },
                    ].map(({ url, img, title, description, id }) => (
                      <div key={id} className="support-col">
                        <Link to={url} className="single-support-box">
                          <img src={img} alt={title} />
                          <h2>{title}</h2>
                          <p>{description}</p>
                        </Link>
                      </div>
                    ))}
                    <div className="support-col">
                      <a
                        onClick={anchorClickHandler}
                        href="#faq"
                        className="single-support-box">
                        <img src="/assets/img/faq.svg" alt="FAQs" />
                        <h2>FAQs</h2>
                        <p>
                          Check out the questions frequently asked by our users
                          and answer yours too.
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="popular-blog-slider grey-bg-support"
              style={{ overflow: 'hidden' }}>
              <div className="container">
                <div className="popular-article-block">
                  <h2>Popular Articles</h2>

                  <div
                    className="single-popular-article flex"
                    style={{ margin: '0 -15px' }}>
                    {allPosts.slice(0, 8).map(({ node }) => (
                      <React.Fragment key={node.id}>
                        <div
                          style={{
                            transform: `translateX(-${sliderActive * 100}%)`,
                          }}>
                          <ArchivePostSingle
                            link={`/blog/${node.slug}/`}
                            title={node.title}
                            image={node.archiveThumbnail.fluid}
                            excerpt={node.excerpt.excerpt}
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  </div>

                  <div className="center">
                    <button
                      type="button"
                      onClick={sliderClickHandler}
                      data-type="prev"
                      className={`slider-arrow arrow-left ${
                        sliderActive === 0 ? 'disabled' : ''
                      }`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512">
                        <path
                          fill="none"
                          stroke="#6552ff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="48"
                          d="M268 112l144 144-144 144M392 256H100"></path>
                      </svg>
                    </button>
                    <button
                      type="button"
                      onClick={sliderClickHandler}
                      data-type="next"
                      className={`slider-arrow arrow-right ${
                        sliderActive === 5 ? 'disabled' : ''
                      }`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512">
                        <path
                          fill="none"
                          stroke="#6552ff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="48"
                          d="M268 112l144 144-144 144M392 256H100"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </section>

            <section className="grey-bg-support">
              <div className="container">
                <main className="blog-list-support">
                  <div className="other-articles-block">
                    <h3>Other Articles</h3>
                    <ul>
                      <li>
                        <Link to="/blog/best-tools-for-freelance-designers/">
                          5 Tools for Freelance Designers
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/website-wireframes/">
                          Everything You Need To Know About Wireframes
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/heat-maps/">
                          How Heat Maps Improve Website Design and UX
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/best-web-design-courses/">
                          A Curated List Of The Best Web Design Courses for
                          Beginners
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/how-to-build-ui-ux-portfolio/">
                          How to Build a Powerful UI UX Portfolio
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/best-product-management-tools/">
                          Best Tools for Product Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/customer-experience-strategy/">
                          5 Ways to Create A Great Customer Experience Strategy
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/material-design/">
                          Considering Material Design? Here&apos;s What You
                          Should Know
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/good-visual-feedback/">
                          What’s the Difference Between Critiquing &amp;
                          Criticizing?
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/the-importance-of-design-thinking/">
                          The Importance of Design Thinking
                        </Link>
                      </li>
                    </ul>
                  </div>
                </main>
              </div>
            </section>
            <section className="support-footer-block">
              <div className="container">
                <div className="flex flex-wrap" style={{ margin: '0 -15px' }}>
                  <button
                    type="button"
                    onClick={() => signUp(toggleSignup)}
                    className="flex-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ padding: '0 15px' }}>
                    <div className="support-footer-single">
                      <img
                        src="/assets/img/get-started.svg"
                        alt="Get Started"
                      />
                      <h2>Get Started</h2>
                      <p>Click here to sign up and create your account.</p>
                    </div>
                  </button>
                  <a
                    href="https://calendly.com/setup-demo-with-ruttl"
                    className="flex-1"
                    style={{ padding: '0 15px' }}
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="support-footer-single">
                      <img
                        src="/assets/img/schedule-demo.svg"
                        alt="Schedule a demo"
                      />
                      <h2>Schedule a demo</h2>
                      <p>
                        Click here to schedule a demo, and our team will get in
                        touch with you.
                      </p>
                    </div>
                  </a>
                  <button
                    type="button"
                    onClick={toggleContactFeatureUpdate(toggleContactUs)}
                    className="flex-1"
                    style={{ padding: '0 15px' }}>
                    <div className="support-footer-single">
                      <img
                        src="/assets/img/feature-recommendation.svg"
                        alt="Recommend a feature"
                      />
                      <h2>Recommend a feature</h2>
                      <p>
                        Have a feature for us in your mind? Click here to let us
                        know your thoughts.
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            </section>
            <GetStartedCenter toggleSignup={toggleSignup} />
            <NewFaq data={insertFaq(1, supportFaq)} />
          </main>
        </>
      )}
    </Layout>
  );
};
SupportNew.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SupportNew;

export const pageQuery = graphql`
  query SupportNewQuery {
    allContentfulBlogPost(
      filter: { knowledgeBox: { eq: false } }
      limit: 10
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMM DD, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
          knowledgeBox
          category {
            name
          }
        }
      }
    }
  }
`;
